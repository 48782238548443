<template>
  <!-- Error page-->
  <div>
    <b-row>
      <b-col>
        <b-link class="d-flex justify-content-start align-items-center">
          <b-img
            :src="appLogoImage"
            alt="logo"
            height="64px"
            class="mr-1 logo"
          />
          <h2
            class="brand-text"
          >
            {{ appName }}
          </h2>
        </b-link>
      </b-col>
    </b-row>
    <b-row class="p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('Page Not Found') }}  🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          {{ $t('Oops') }}! 😖 {{ $t('The requested URL was not found on this server') }}.
        </p>
        <p>
          <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{path:'/'}"
          >
            <span class="align-middle">{{ $t('Back') }}</span>
          </b-button>
        </p>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </b-row>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BButton, BImg, BRow, BCol,
} from 'bootstrap-vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BButton,
    BImg,
    BRow,
    BCol,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
